export const PLAYER_QUEUE_MAX_SIZE = 1000;

export const FALLBACK_WAVEFORM = `
	data:image/svg+xml,
  ${encodeURIComponent(
			`<svg xmlns="http://www.w3.org/2000/svg" width="1500" height="250">
			<line x1="0" y1="125" x2="1500" y2="125" stroke="#CCCCCC" stroke-width="14" />
    </svg>`,
		)}
`;
